<template>
  <div>
    <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Add teaser Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{errorMessage}}</p>
                <p class="text-h6">{{ errorMessageError }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="800"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind:on="on" v-bind="attrs"></slot>
      </template>
      <v-card>


        <v-card-text>
          <v-card-title class="headline">
            Upload Affiliate Network statistic
          </v-card-title>
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" sm="4">
                <v-autocomplete
                    label="Network"
                    v-model="thisNetworks"
                    :items="pps"
                    required
                    :rules="[networkValidate]"
                    auto-select-first
                    return-object
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-select
                    label="Currency"
                    v-model="currency"
                    :items="currencyItems"
                    required
                    auto-select-first
                    return-object
                >

                  <template v-slot:prepend>
                    <flag :iso="currency.country"/>
                    <!--                    {{currency}}-->
                  </template>

                  <template v-slot:item="{ item }">
                    <flag :iso="item.country" class="mr-3"/>
                    {{item.text}}
                  </template>


                </v-select>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="430px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        label="Date or Date range"
                        v-model="dateRangeText"
                        prepend-icon="mdi-calendar"
                        @click:prepend="menu = true"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>

                  </template>
                  <v-card flat>
                    <v-row no-gutters>
                      <v-col cols="4">

                        <v-list dense>
                          <!--                    <v-subheader class="justify-center"><v-icon >mdi-clock-fast</v-icon></v-subheader>-->
                          <v-subheader class="justify-center">
                            <v-icon>mdi-calendar-range-outline</v-icon>
                          </v-subheader>
                          <v-list-item-group
                              v-model="selectedFastDateRange"
                              color="primary"
                          >
                            <v-list-item
                                v-for="(item, i) in selectedFastDateRangeItems"
                                :key="i"
                            >
                              <!--                        <v-list-item-icon>-->
                              <!--                          <v-icon v-text="item.icon"></v-icon>-->
                              <!--                        </v-list-item-icon>-->
                              <v-list-item-content @click="onClickFastDateInterval(item.value)" v-if="item.visible()">
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                        </v-list>
                        <v-switch v-model="switch1"
                                  label="Month"
                                  class="pl-6"
                                  color="red">

                        </v-switch>
                      </v-col>
                      <v-col cols="8">
                        <v-date-picker
                            v-model="date"
                            :show-current="todayDate()"
                            reactive
                            first-day-of-week="1"
                            :type="typeOfCalendar"
                        ></v-date-picker>
                        <!--                  <v-spacer></v-spacer>-->
                        <div class="text-right">
                          <v-btn
                              text
                              color="primary"
                              @click="menu = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(date)"
                          >
                            OK
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>

                </v-menu>
                <!--          <v-row>-->
                <!--            <v-col-->
                <!--                cols="12"-->
                <!--                sm="6"-->
                <!--            >-->
                <!--              <v-date-picker-->
                <!--                  v-model="dates"-->
                <!--                  range-->
                <!--              ></v-date-picker>-->
                <!--            </v-col>-->
                <!--            <v-col-->
                <!--                cols="12"-->
                <!--                sm="6"-->
                <!--            >-->
                <!--              <v-text-field-->
                <!--                  v-model="dateRangeText"-->
                <!--                  label="Date range"-->
                <!--                  prepend-icon="mdi-calendar"-->
                <!--                  readonly-->
                <!--              ></v-text-field>-->
                <!--              model: {{ dates }}-->
                <!--            </v-col>-->
                <!--          </v-row>-->
                <!--          -->
              </v-col>

              <v-col cols="12" sm="12">
                <v-file-input
                    truncate-length="100"
                    accept=".csv"
                    label="File"
                    ref="file"
                    id="file"
                    v-on:change="handleFileUpload()"
                ></v-file-input>
              </v-col>

              <!--                  <template v-slot:prepend>-->
              <!--                    <flag :iso="thisCountry"/>-->
              <!--                  </template>-->

              <!--                  <template v-slot:item="{ item }">-->
              <!--                    <flag :iso="item.value" class="mr-3"/>-->
              <!--                    {{item.text}}-->
              <!--                  </template>-->

              <!--                </v-autocomplete>-->
              <!--              </v-col>-->
              <!--              <v-col cols="12" sm="2">-->
              <!--                <v-text-field-->
              <!--                    label="Offer"-->
              <!--                    dense-->
              <!--                    v-model="thisOffer"-->
              <!--                    :rules="[offerValidate]"-->
              <!--                    :readonly="ro"-->
              <!--                    required-->
              <!--                ></v-text-field>-->
              <!--              </v-col>-->
              <!--              <v-col cols="12" sm="8">-->
              <!--                <v-row>-->
              <!--                  <v-col cols="12" sm="1" class="mt-2 pb-0">-->
              <!--                    <v-icon>mdi-shape-outline</v-icon>-->
              <!--                  </v-col>-->
              <!--                  <v-col cols="12" sm="3">-->
              <!--                    <v-select-->
              <!--                        label="Ad type"-->
              <!--                        dense-->
              <!--                        v-model="thisAd_type"-->
              <!--                        :items="landingTypes"-->
              <!--                        :rules="[adTypeValidate]"-->
              <!--                        :readonly="ro"-->
              <!--                        required-->
              <!--                    ></v-select>-->
              <!--                  </v-col>-->
              <!--                  <v-col cols="12" sm="3">-->
              <!--                    <v-select-->
              <!--                        label="Landing type"-->
              <!--                        dense-->
              <!--                        v-model="thisLanding_type"-->
              <!--                        :items="landingTypes"-->
              <!--                        :rules="[landingTypeValidate]"-->
              <!--                        :readonly="ro"-->
              <!--                        required-->
              <!--                    ></v-select>-->
              <!--                  </v-col>-->
              <!--                  <v-col cols="12" sm="5">-->
              <!--                    <v-autocomplete-->
              <!--                        label="IAB category"-->
              <!--                        dense-->
              <!--                        v-model="thisCatId"-->
              <!--                        :items="iabCategories"-->
              <!--                        :rules="[iabCatValidate]"-->
              <!--                        :readonly="ro"-->
              <!--                        return-object-->
              <!--                        required-->
              <!--                        item-text="textAndId"-->
              <!--                    >-->
              <!--                    </v-autocomplete>-->
              <!--                  </v-col>-->
              <!--                </v-row>-->
              <!--              </v-col>-->

              <!--              <v-col cols="12" sm="2">-->
              <!--                <v-text-field-->
              <!--                    v-model="thisPayout"-->
              <!--                    label="Payout"-->
              <!--                    type="string"-->
              <!--                    prepend-icon="mdi-cash"-->
              <!--                    :rules="[payoutValidate]"-->
              <!--                    :readonly="ro"-->
              <!--                    required-->
              <!--                >-->
              <!--                  <template v-slot:append-outer>-->
              <!--                    $-->
              <!--                  </template>-->
              <!--                </v-text-field>-->
              <!--              </v-col>-->
              <!--              <v-col cols="12" sm="2">-->
              <!--                <v-text-field-->
              <!--                    v-model="thisApprove"-->
              <!--                    label="Approve"-->
              <!--                    type="string"-->
              <!--                    prepend-icon="mdi-check-decagram"-->
              <!--                    :rules="[approveValidate]"-->
              <!--                    :readonly="ro"-->
              <!--                    required-->
              <!--                >-->
              <!--                  <template v-slot:append-outer>-->
              <!--                    %-->
              <!--                  </template>-->
              <!--                </v-text-field>-->
              <!--              </v-col>-->
              <!--              <v-col cols="12" sm="1" class="pl-4 pt-4">-->
              <!--                <v-row>-->
              <!--                  <span class="text-caption pl-2 text-center pt-0">Only 'Action'</span>-->
              <!--                </v-row>-->
              <!--                <v-row>-->
              <!--                  <v-checkbox color="red" v-model="thisNo_leads" :readonly="ro">-->
              <!--                    <template v-slot:prepend>-->
              <!--                      <v-icon class="pl-3 pt-0">mdi-alert-circle</v-icon>-->
              <!--                    </template>-->
              <!--                  </v-checkbox>-->
              <!--                </v-row>-->
              <!--              </v-col>-->
              <!--              <v-col cols="12" sm="7">-->
              <!--                <v-text-field-->
              <!--                    v-model="thisButton_text"-->
              <!--                    label="Button text"-->
              <!--                    type="string"-->
              <!--                    :rules="[buttonTextValidate]"-->
              <!--                    :readonly="ro"-->
              <!--                    required-->
              <!--                ></v-text-field>-->
              <!--              </v-col>-->
            </v-row>

            <v-row>
            </v-row>
            <!--            <v-row class="pb-0">-->
            <!--              <v-col cols="12" sm="4">-->
            <!--                <v-row class="pt-8">-->
            <!--                  <v-col cols="12">-->
            <!--                    <v-img :src="thisImage"-->
            <!--                           width="300"-->
            <!--                           :aspect-ratio="16/9"-->
            <!--                    ></v-img>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--              </v-col>-->
            <!--              <v-col cols="12" sm="8">-->

            <!--                <v-text-field-->
            <!--                    v-bind:value="thisLink"-->
            <!--                    label="Click-URL"-->
            <!--                    type="string"-->
            <!--                    :rules="[linkValidate]"-->
            <!--                    :readonly="ro"-->
            <!--                    required-->
            <!--                    @input="setLink($event)"-->
            <!--                >-->
            <!--                  &lt;!&ndash;                  <template v-slot:append-outer>&ndash;&gt;-->
            <!--                  <template v-slot:append-outer>-->
            <!--                    <v-btn icon :href="thisLink" target="_blank"-->
            <!--                           :disabled="thisLink.length=== 0 || thisLink=== null">-->
            <!--                      <v-icon>mdi-open-in-new</v-icon>-->
            <!--                    </v-btn>-->
            <!--                  </template>-->
            <!--                </v-text-field>-->

            <!--                <v-text-field-->
            <!--                    v-model="thisImage"-->
            <!--                    label="Image"-->
            <!--                    type="string"-->
            <!--                    required-->
            <!--                    :rules="[imageValidate]"-->
            <!--                    :readonly="ro"-->
            <!--                ></v-text-field>-->

            <!--                <v-row>-->
            <!--                  <v-col cols="12" :sm="teams.length>1? 2 : 3">-->
            <!--                    <v-text-field-->
            <!--                        label="MGID teaser ID"-->
            <!--                        type="string"-->
            <!--                        v-model="thisMgid_id"-->
            <!--                        :rules="[mgidIdValidate]"-->
            <!--                        :readonly="createNew ? ro : ro || allowSendModerateAgain"-->
            <!--                    >-->
            <!--                    </v-text-field>-->
            <!--                  </v-col>-->
            <!--                  &lt;!&ndash;                  <v-col cols="12" :sm="teams.length>1? 5 : 5">&ndash;&gt;-->
            <!--                  <v-col cols="12" :sm="teams.value.length>1? 4 : 5">-->
            <!--                    <v-autocomplete label="PP"-->
            <!--                                    v-model="thisPp"-->
            <!--                                    :items="pps"-->
            <!--                                    required-->
            <!--                                    :rules="[ppValidate]"-->
            <!--                                    :readonly="ro"-->
            <!--                    ></v-autocomplete>-->
            <!--                  </v-col>-->
            <!--                  <v-col cols="12" sm="3" v-if="teams.value.length>1">-->
            <!--                    <v-autocomplete label="Teams"-->
            <!--                                    return-object-->
            <!--                                    v-model="thisTeam"-->
            <!--                                    :items="teams.value"-->
            <!--                                    :readonly="ro"-->
            <!--                                    :rules="[teamValidate]"-->
            <!--                    ></v-autocomplete>-->
            <!--                  </v-col>-->
            <!--                  <v-col cols="12" :sm="teams.value.length>1? 2 : 4">-->
            <!--                    <v-autocomplete label="Owner"-->
            <!--                                    v-model="thisOwner"-->
            <!--                                    :items="thisTeam.value"-->
            <!--                                    :disabled="!thisTeam.value || thisTeam.value.length === 0"-->
            <!--                                    :readonly="ro"-->
            <!--                                    :rules="[ownerValidate]"-->
            <!--                    >-->

            <!--                    </v-autocomplete>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->

            <!--                <v-row>-->
            <!--                  <v-col cols="12">-->
            <!--                    <v-text-field-->
            <!--                        v-bind:value="thisTitle"-->
            <!--                        @input="setTitle($event)"-->
            <!--                        label="Title"-->
            <!--                        type="string"-->
            <!--                        :rules="[titleValidate]"-->
            <!--                        :readonly="ro"-->
            <!--                        required-->
            <!--                    ></v-text-field>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
            <!--            <v-row class="py-3 pb-5">-->
            <!--              <v-divider></v-divider>-->
            <!--            </v-row>-->
            <!--            <v-row justify="center" align="center">-->
            <!--              <v-switch v-model="thisAbtest_on" label="Enable" :readonly="ro" hide-details></v-switch>-->
            <!--              <v-icon class="pl-4 pt-4">mdi-ab-testing</v-icon>-->
            <!--              <div class="pt-4 pl-5 text-subtitle-1">test</div>-->
            <!--            </v-row>-->
            <!--            <v-row v-if="thisAbtest_on" class="pt-9">-->
            <!--              <v-divider></v-divider>-->
            <!--            </v-row>-->
            <!--            <v-row v-if="thisAbtest_on" class="pt-0">-->
            <!--              <v-col cols="12" sm="4" class="pt-6">-->
            <!--                <v-row>-->
            <!--                  <v-col cols="12">-->
            <!--                    <v-img :src="thisImage_2"-->
            <!--                           width="300"-->
            <!--                           :aspect-ratio="16/9"-->
            <!--                    ></v-img>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--              </v-col>-->
            <!--              <v-col cols="12" sm="8">-->
            <!--                <v-row>-->

            <!--                  <v-col cols="12">-->
            <!--                    <v-text-field-->
            <!--                        v-bind:value="thisLink_2"-->
            <!--                        label="Click-URL"-->
            <!--                        type="string"-->
            <!--                        :rules="[linkValidate]"-->
            <!--                        :readonly="ro"-->
            <!--                        required-->
            <!--                        @input="setLink2($event)"-->
            <!--                    >-->
            <!--                      <template v-slot:append-outer>-->
            <!--                        <v-btn icon :href="thisLink_2" target="_blank"-->
            <!--                               :disabled="thisLink_2.length=== 0 || thisLink_2=== null">-->
            <!--                          <v-icon>mdi-open-in-new</v-icon>-->
            <!--                        </v-btn>-->
            <!--                      </template>-->
            <!--                    </v-text-field>-->
            <!--                  </v-col>-->

            <!--                  <v-col cols="12">-->
            <!--                    <v-text-field-->
            <!--                        v-model="thisImage_2"-->
            <!--                        :readonly="ro"-->
            <!--                        label="Image - 2"-->
            <!--                        type="string"-->
            <!--                        :rules="[image2Validate]"-->
            <!--                    ></v-text-field>-->
            <!--                  </v-col>-->

            <!--                  <v-col cols="12" :sm="teams.length>1? 2 : 3">-->
            <!--                    <v-text-field-->
            <!--                        label="MGID teaser ID - 2"-->
            <!--                        type="string"-->
            <!--                        v-model="thisMgid_id_2"-->
            <!--                        :readonly="ro"-->
            <!--                        :rules="[mgidId2Validate]"-->
            <!--                    >-->
            <!--                    </v-text-field>-->
            <!--                  </v-col>-->
            <!--                  <v-col cols="12" sm="5">-->
            <!--                    <v-autocomplete label="PP - 2"-->
            <!--                                    v-model="thisPp_2"-->
            <!--                                    :items="pps"-->
            <!--                                    :readonly="ro"-->
            <!--                                    :rules="[pp2Validate]">-->

            <!--                    </v-autocomplete>-->
            <!--                  </v-col>-->
            <!--                  <v-col cols="12" sm="4">-->
            <!--                    <v-autocomplete label="Owner - 2"-->
            <!--                                    v-model="thisOwner_2"-->
            <!--                                    :items="thisTeam.value"-->
            <!--                                    :disabled="!thisTeam.value || thisTeam.value.length === 0"-->
            <!--                                    :readonly="ro"-->
            <!--                                    :rules="[ownerValidate]"-->
            <!--                                    required-->
            <!--                    >-->

            <!--                    </v-autocomplete>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--                <v-row>-->
            <!--                  <v-col cols="12">-->
            <!--                    <v-text-field-->
            <!--                        v-bind:value="thisTitle_2"-->
            <!--                        @input="setTitle2($event)"-->
            <!--                        :readonly="ro"-->
            <!--                        label="Title - 2"-->
            <!--                        type="string"-->
            <!--                        :rules="[title2Validate]"-->
            <!--                    ></v-text-field>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>

          <!--          <v-btn v-if="createNew" color="green darken-1" :disabled="!valid" @click="createNewTeaser">-->
          <!--            Send to Moderation-->
          <!--            <v-icon>mdi-send</v-icon>-->
          <!--          </v-btn>-->


          <!--          <v-btn v-if="!createNew && allowSendModerateAgain" color="green darken-1" :disabled="!valid"-->
          <!--                 @click="sendModerateAgain">-->
          <!--            Send to Moderation-->
          <!--            <v-icon>mdi-send</v-icon>-->
          <!--          </v-btn>-->
          <!--          <v-btn-->
          <!--              v-if="allowCancelModeration && team === dspUserTeam"-->
          <!--              color="red lighten-2" @click="cancelModeration()">-->
          <!--            Cancel moderation-->
          <!--            <v-icon>mdi-cancel</v-icon>-->
          <!--          </v-btn>-->


          <!--          <v-btn v-if="allowModerate " color="green darken-1" :disabled="!valid" @click="doModeration">-->
          <!--            Start-->
          <!--            <v-icon>mdi-airplane-takeoff</v-icon>-->
          <!--          </v-btn>-->
          <v-btn color="green darken-1" :disabled="!valid || formData === null" @click="sendFile">
            Upload
            <v-icon>mdi-upload</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import api from '@/utils/api'
  // import { IFACE_DSP_TEASERS_ON_MODERATION_OFF, IFACE_DSP_TEASERS_ON_MODERATION_ON } from '@/store/actions/iface'

  import { DateTime } from 'luxon'

  export default {
    name: 'EarningsNetworkWindowAddStatistic',
    props: {

      // countries: {default: []},
      // offers: {default: []},
      // iabCategories: {default: []},
      // landingTypes: {default: []},
      pps: {default: []},
      // // depsTeams: {default: []},
      //
      // country: {
      //   default: ''
      // },
      // no_leads: {
      //   default: false
      // },
      // cat: {
      //   default: ''
      // },
      // ad_type: {
      //   default: ''
      // },
      // landing_type: {
      //   default: ''
      // },
      // offer: {
      //   default: ''
      // },
      // payout: {
      //   default: 0
      // },
      // approve: {
      //   default: 50
      // },
      // button_text: {
      //   default: ''
      // },
      // team: {
      //   default: 0
      // },
      // owner: {
      //   default: ''
      // },
      // pp: {
      //   default: ''
      // },
      // mgid_id: {
      //   default: null
      // },
      // link: {
      //   default: ''
      // },
      // title: {
      //   default: ''
      // },
      // image: {
      //   default: ''
      // },
      // abtest_on: {
      //   default: false
      // },
      // pp_2: {
      //   default: ''
      // },
      // owner_2: {
      //   default: ''
      // },
      // mgid_id_2: {
      //   default: null
      // },
      // link_2: {
      //   default: ''
      // },
      // title_2: {
      //   default: ''
      // },
      // image_2: {
      //   default: ''
      // },
      //
      // isReadyModeration: {
      //   default: false
      // },
      //
      // cat_id: {
      //   default: null
      // },
      //
      // createNew: {
      //   default: false
      // },
      // copy: {
      //   default: false
      // },
      // ro: {
      //   default: false
      // },

      value: {
        default: false
      }
    },
    data () {
      return {
        dialog: this.value,

        menu: false,
        date: null,
        dates: [],
        selectedFastDateRange: 1,
        selectedFastDateRangeItems: [
          {text: 'Today', value: 'today', visible: () => !this.switch1},
          {text: 'Yesterday', value: 'yesterday', visible: () => !this.switch1},
          {text: 'This month', value: 'thisMonth', visible: () => this.switch1},
          {text: 'Last month', value: 'lastMonth', visible: () => this.switch1},
        ],

        valid: false,

        thisNetworks: null,

        currency: {text: '$, USA', value: 'USA-dollar', country: 'US'},
        currencyItems: [
          {text: '$, USA', value: 'USA-dollar', country: 'US'},
          {text: '€, Europe', value: 'Europe-euro', country: 'EU'},
          {text: '₽, Ruble', value: 'Russian-ruble', country: 'RU'},
          {text: '₴, Hryvnia', value: 'Ukraine-hryvnia', country: 'UA'}
        ],

        switch1: false,

        // thisCountry: this.country,
        // thisNo_leads: this.no_leads,
        // thisCat: this.cat,
        // thisCatId: this.cat_id,
        // thisAd_type: this.ad_type,
        // thisLanding_type: this.landing_type,
        // thisOffer: this.offer,
        // thisPayout: this.payout,
        // thisApprove: this.approve,
        // thisButton_text: this.button_text,
        // thisPp: this.pp,
        // thisOwner: this.owner,
        // thisMgid_id: this.mgid_id,
        // thisLink: '',
        // rawLink: '',
        // thisTitle: this.title,
        // thisImage: this.image,
        // thisAbtest_on: this.abtest_on,
        // thisPp_2: this.pp_2,
        // thisOwner_2: this.owner_2,
        // thisMgid_id_2: this.mgid_id_2,
        // thisLink_2: this.link_2,
        // thisTitle_2: this.title_2,
        // thisImage_2: this.image_2,
        //
        // thisIsReadyModeration: this.isReadyModeration,
        isVisibleValidationErrorDialog: false,
        //
        errorMessage: '',
        errorMessageError: '',
        //
        // depTeams: null,
        //
        // // teams: {value: []},
        //
        // thisTeam: {value: []},
        //
        // init: false
        file: '',

        formData: null,

        typeOfCalendar: 'date'
      }
    },

    created () {
      this.date = this.yesterdayDate()

      // this.dates = [date, date]
      // this.setLink(this.link)
      // // let catalog
      // // try {
      // //   catalog = await api.getCatalogForTeasers()
      // // } catch (e) {
      // //   console.error('created', e)
      // //   return
      // // }
      //
      // // this.thisOwner = this.createNew ? this.currentOwner : this.owner
      //
      // if (this.teams.value.length === 1) {
      //   this.thisTeam = this.teams.value[0]
      //   // this.thisTeam_2 = this.teams.value[0]
      // }
      //
      // if (this.team !== null) {
      //   const team = this.teams.value.find(t => t.id === this.team)
      //
      //   this.init = true
      //   if (team) {
      //     this.thisTeam = team
      //   }
      // }
      //
      // if (this.onModeration) {
      //   const r = this.iabCategories.find(c => c.value === this.cat_id)
      //   console.log('iabCategories', r)
      //   if (r) {
      //     this.$set(this, 'thisCatId', r)
      //   }
      // }
      // // console.log('created team', this.team, this.teams)
      //
      // // console.log('created createNew', this.createNew ? this.currentOwner : this.owner)
      // // console.log('created dialog', this.dialog)
      // // console.log('created offer', this.offer)
      // // console.log('created country', this.country)
      // // console.log('created copy', this.copy)
      // // console.log('created isReadyModeration', this.isReadyModeration)
      //
      // // this.$set(this, 'countries', catalog.data.countries)
      // // this.$set(this, 'offers', catalog.data.offers)
      // // this.$set(this, 'iabCategories', catalog.data.iabCategories)
      // // this.$set(this, 'landingTypes', catalog.data.landingTypes)
      // // this.$set(this, 'pps', catalog.data.pps)
    },

    computed: {
      dateRangeText () {
        if (this.isToday()) return 'Today'
        if (this.isYesterday()) return 'Yesterday'
        if (this.isThisMonth()) return 'This month'
        if (this.isLastMonth()) return 'Last month'
        return this.date
      },

      // onModeration: {
      //   get () {
      //     return this.$store.getters['iface/isDspTeasersOnModeration']
      //   }
      // },
      //
      // allowEdit () {
      //   return !this.copy && this.onModeration && this.$store.getters['user/isDspModerator']
      // },
      //
      // allowCancelModeration () {
      //   return !this.copy && this.onModeration && this.thisIsReadyModeration &&
      //     (this.$store.getters['user/isDspModerator'] ||
      //       (this.$store.getters['user/getIsDspTeamModerator'] && this.$store.getters['user/getDspTeam'] === this.thisTeam.id)
      //     )
      // },
      //
      // allowModerate () {
      //   return (
      //     !this.copy && this.thisIsReadyModeration &&
      //     (this.$store.getters['user/isDspModerator'] ||
      //       (this.$store.getters['user/getIsDspTeamModerator'] && this.$store.getters['user/getDspTeam'] === this.thisTeam.id)
      //     ))
      // },
      //
      // allowSendModerateAgain () {
      //   return (!this.copy && this.onModeration && !this.thisIsReadyModeration)
      // },
      //
      // currentOwner () {
      //   return this.$store.getters['user/getUserName']
      // },
      // isDspModerator () {
      //   return this.$store.getters['user/isDspModerator']
      // },
      //
      // dep: {
      //   get () {
      //     return this.$store.getters['iface/getDspDep']
      //   }
      // },
      //
      // depsAndTeams: {
      //   get () {
      //     return this.$store.getters['iface/getDspDeps']
      //   }
      // },
      //
      // teams: {
      //   get () {
      //     return this.$store.getters['iface/getDspDepTeams']
      //   }
      // },
      //
      // dspUserTeam: {
      //   get () {
      //     return this.$store.getters['user/getDspTeam']
      //   }
      // }
    },

    beforeDestroy () {
      this.$emit('input', false)
    },

    methods: {
      todayDate () {
        return DateTime.local().setZone(this.tz).toISODate()
      },

      yesterdayDate () {
        return DateTime.local().setZone(this.tz).endOf('day').plus({days: -1}).toISODate()
      },

      thisMonth () {
        return DateTime.local().setZone(this.tz).startOf('month').toFormat('yyyy-MM')
      },

      lastMonth () {
        return DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toFormat('yyyy-MM')
      },

      isToday () {
        return this.todayDate() === this.date
      },

      isYesterday () {
        return this.yesterdayDate() === this.date
      },

      isThisMonth () {
        return this.thisMonth() === this.date
      },

      isLastMonth () {
        return this.lastMonth() === this.date
      },

      onClickFastDateInterval (value) {
        switch (value) {
          case 'today' :
            // this.dates = [this.todayDate()]
            this.date = this.todayDate()
            break
          case 'yesterday' :
            // this.dates = [this.yesterdayDate()]
            this.date = this.yesterdayDate()
            break
          case 'thisMonth' :
            this.date = this.thisMonth()
            break
          case 'lastMonth' :
            this.date = this.lastMonth()
            break
          default:
            throw `Unknown ${value}`
        }
      },

      // onclickOkPeekDate () {
      // this.getDataFromApi()
      // },

      handleFileUpload () {
        // console.log('this.$refs.file', this.$refs.file.$refs.input.files[0])

        this.formData = new FormData()

        if (this.switch1) {
          this.formData.append('month', true)
          this.formData.append('date', DateTime.fromISO(this.date).endOf('month').toISODate())
        } else {
          this.formData.append('month', false)
          this.formData.append('date', this.date)
        }

        this.formData.append('networkName', this.thisNetworks.name)
        this.formData.append('networkCabName', this.thisNetworks.value)
        this.formData.append('currency', this.currency.value)
        this.formData.append('file', this.$refs.file.$refs.input.files[0])

      },

      async sendFile () {
        try {
          const res = await api.uploadNetworkStatisticData(this.formData)


          if (!res.data.error) {
            console.info('uploadNetworkStatisticData Res', res.data)
            this.$emit('input', true)
            this.dialog = false
          } else {
            this.errorMessage = res.data.msg
            this.errorMessageError = res.data.e
            this.isVisibleValidationErrorDialog = true
          }

        } catch (e) {
          console.error('uploadNetworkStatisticData', e)
        }
      },

      networkValidate (value) {
        if (value) {
          return true
        } else {
          return 'Select network'
        }
      },
      // getTeaserObject () {
      //   if (this.thisAbtest_on)
      //     return {
      //       country: this.thisCountry,
      //       no_leads: this.thisNo_leads,
      //       cat: this.thisCatId.cat,
      //       cat_id: this.thisCatId.value,
      //       ad_type: this.thisAd_type,
      //       landing_type: this.thisLanding_type,
      //       offer: this.thisOffer.trim(),
      //       payout: parseFloat(this.thisPayout),
      //       approve: this.thisApprove,
      //       button_text: this.thisButton_text,
      //       pp: this.thisPp,
      //       dep: this.teams.text,
      //       team: this.thisTeam.id,
      //       owner: this.thisOwner,
      //       mgid_id: parseInt(this.thisMgid_id),
      //       link: this.thisLink.trim(),
      //       title: this.thisTitle.trim(),
      //       image: this.thisImage.trim(),
      //       abtest_on: this.thisAbtest_on,
      //       pp_2: this.thisPp_2,
      //       link_2: this.thisLink_2.trim(),
      //       owner_2: this.thisOwner_2,
      //       mgid_id_2: this.thisMgid_id_2,
      //       title_2: this.thisTitle_2.trim(),
      //       image_2: this.thisImage_2.trim()
      //     }
      //   else
      //     return {
      //       country: this.thisCountry,
      //       no_leads: !!this.thisNo_leads,
      //       cat: this.thisCatId.cat,
      //       cat_id: this.thisCatId.value,
      //       ad_type: this.thisAd_type,
      //       landing_type: this.thisLanding_type,
      //       offer: this.thisOffer.trim(),
      //       payout: parseFloat(this.thisPayout),
      //       approve: this.thisApprove,
      //       button_text: this.thisButton_text,
      //       pp: this.thisPp,
      //       dep: this.teams.text,
      //       team: this.thisTeam.id,
      //       owner: this.thisOwner,
      //       mgid_id: parseInt(this.thisMgid_id),
      //       link: this.thisLink.trim(),
      //       title: this.thisTitle.trim(),
      //       image: this.thisImage.trim(),
      //       abtest_on: 0,
      //       pp_2: '',
      //       owner_2: '',
      //       link_2: '',
      //       mgid_id_2: 0,
      //       title_2: '',
      //       image_2: ''
      //     }
      // },
      //
      // async createNewTeaser () {
      //   try {
      //     const res = await api.dspTeaserNew(this.getTeaserObject())
      //     if (!res.data.error) {
      //       console.info('dspTeaserNew', res)
      //       this.$emit('saved')
      //       this.dialog = false
      //     } else {
      //       this.errorMessage = res.data.message
      //       this.isVisibleValidationErrorDialog = true
      //     }
      //   } catch (e) {
      //     console.error('dspTeaserNew', e)
      //   }
      // },
      //
      // async sendModerateAgain () {
      //   try {
      //     const res = await api.dspTeaserSendModerateAgain(this.getTeaserObject())
      //     if (!res.data.error) {
      //       console.info('sendModerateAgain', res)
      //       this.$emit('saved')
      //       this.dialog = false
      //     } else {
      //       this.errorMessage = res.data.message
      //       this.isVisibleValidationErrorDialog = true
      //     }
      //   } catch (e) {
      //     console.error('dspTeaserNew', e)
      //   }
      // },
      //
      // async cancelModeration () {
      //   try {
      //     const res = await api.dspTeaserCancelModerate({id: this.thisMgid_id})
      //     if (!res.data.error) {
      //       this.$emit('cancel-moderation')
      //       this.dialog = false
      //     } else {
      //       this.errorMessage = res.data.message
      //       this.isVisibleValidationErrorDialog = true
      //     }
      //   } catch (e) {
      //     console.error('cancelModeration', e)
      //   }
      // },
      //
      // async doModeration () {
      //   try {
      //     const res = await api.dspTeaserModerate({id: this.thisMgid_id})
      //     if (!res.data.error) {
      //       this.$emit('do-moderation')
      //       this.dialog = false
      //     } else {
      //       this.errorMessage = res.data.message
      //       this.isVisibleValidationErrorDialog = true
      //     }
      //   } catch (e) {
      //     console.error('doModeration', e)
      //   }
      // },
      //
      // countryValidate (value) {
      //   if (value) {
      //     return value.length > 0
      //   } else {
      //     return 'Choice country'
      //   }
      // },
      //
      // offerValidate (value) {
      //   if (value) {
      //     if (this.offers.indexOf(value) !== -1) return 'Come up with another name'
      //     if (value.length === 0) return 'Come up with a name'
      //
      //     const match = /(^[^_].*)_(..)$/.exec(value)
      //
      //     if (match) {
      //       if (match[2] !== this.thisCountry) {
      //         return `GEO: '${match[2]}' not eq '${this.thisCountry}'`
      //       } else {
      //         return true
      //       }
      //     } else {
      //       return 'Not fount GEO in offername'
      //     }
      //   } else {
      //     return 'Come up with a name'
      //   }
      // },
      //
      // adTypeValidate (value) {
      //   if (value) {
      //     return value.length > 0
      //   } else {
      //     return 'Choice Ad type'
      //   }
      // },
      //
      // landingTypeValidate (value) {
      //   if (value) {
      //     return value.length > 0
      //   } else {
      //     return 'Choice Landing type'
      //   }
      // },
      //
      //
      // payoutValidate (value) {
      //   let i = parseInt(value)
      //   if (Number.isFinite(i)) {
      //     if (i <= 0)
      //       return 'Set more than 0'
      //     return true
      //   } else {
      //     return 'Set more than 0'
      //   }
      // },
      //
      // approveValidate (value) {
      //   let i = parseInt(value)
      //   if (Number.isFinite(i)) {
      //     if (i <= 0)
      //       return 'Set more than 0'
      //     return true
      //   } else {
      //     return 'Set more than 0'
      //   }
      // },
      //
      // linkValidate (value) {
      //   if (/https:\/\/www\.shndrktrk\.com\/.+/.test(value) || /https:\/\/track\.trcsrchd\.xyz\/.+/.test(value)) {
      //     return true
      //   } else {
      //     return 'Link must be: https://www.shndrktrk.com/..  or https://track.trcsrchd.xyz/...'
      //   }
      // },
      //
      // buttonTextValidate (value) {
      //   if (value) {
      //     return value.length > 0
      //   } else {
      //     return 'Not empty'
      //   }
      // },
      //
      // imageValidate (value) {
      //   if (/https:\/\/img\.rtbsystem\.org\//.test(value)) {
      //     return true
      //   } else {
      //     return 'Link must be: https://img.rtbsystem.org/...'
      //   }
      // },
      //
      // image2Validate (value) {
      //   if (!this.thisAbtest_on) {
      //     return true
      //   }
      //   if (/https:\/\/img\.rtbsystem\.org\//.test(value)) {
      //     return true
      //   } else {
      //     return 'Link must be: https://img.rtbsystem.org/...'
      //   }
      // },
      //
      // mgidIdValidate (value) {
      //   if (value == null || value.length === 0) return 'Set teaser ID '
      //   let i = parseInt(value)
      //   if (Number.isFinite(i)) {
      //     if (i <= 0)
      //       return 'Set more than 0'
      //     return true
      //   } else {
      //     return 'Set more than 0'
      //   }
      // },
      //
      // mgidId2Validate (value) {
      //   if (!this.thisAbtest_on) {
      //     return true
      //   }
      //   if (value == null || value.length === 0) return 'Set teaser ID '
      //   let i = parseInt(value)
      //   if (Number.isFinite(i)) {
      //     if (i <= 0)
      //       return 'Set more than 0'
      //     return true
      //   } else {
      //     return 'Set more than 0'
      //   }
      // },
      //
      // titleValidate (value) {
      //   if (value) {
      //     if (value.length === 0)
      //       return 'Not empty'
      //     return true
      //   } else {
      //     return 'Not empty'
      //   }
      // },
      //
      // title2Validate (value) {
      //   if (!this.thisAbtest_on) {
      //     return true
      //   }
      //   if (value) {
      //     if (value.length === 0)
      //       return 'Not empty'
      //     return true
      //   } else {
      //     return 'Not empty'
      //   }
      // },
      //
      // ppValidate (value) {
      //   if (value) {
      //     return value.length > 0
      //   } else {
      //     return 'Select PP'
      //   }
      // },
      //
      // depsValidate (value) {
      //   if (value) {
      //     if (value.length === 0) return 'Select Dep'
      //     return true
      //   } else {
      //     return 'Select Dep'
      //   }
      // },
      //
      // teamValidate (value) {
      //   if (value) {
      //     if (value.value.length === 0) return 'Select Team'
      //     return true
      //   } else {
      //     return 'Select Team'
      //   }
      // },
      //
      // team2Validate (value) {
      //   if (value) {
      //     if (value.value.length === 0) return 'Select Team'
      //     return true
      //   } else {
      //     return 'Select Team'
      //   }
      // },
      //
      // pp2Validate (value) {
      //   if (!this.thisAbtest_on) {
      //     return true
      //   }
      //   if (value) {
      //     return value.length > 0
      //   } else {
      //     return 'Select PP'
      //   }
      // },
      //
      // ownerValidate (value) {
      //   // console.log('ownerValidate', value)
      //   if (value) {
      //     if (value.length === 0)
      //       return 'Select owner'
      //     return true
      //   } else {
      //     return 'Select owner'
      //   }
      // },
      // //
      // // setLink (v) {
      // //   console.log('setLink', v, this.thisLink)
      // //   const match = /(^[^?].*)(\?.*)$/.exec(v)
      // //   if (match) {
      // //     console.log('rawLink', match, match[1].toString(), this.thisLink)
      // //     this.$nextTick(() => {
      // //       this.$set(this, 'thisLink', match[1].toString())
      // //     })
      // //
      // //   } else {
      // //     if (this.thisLink !== v)
      // //       this.thisLink = v
      // //   }
      // // }
      //
      // setLink (v) {
      //   const splited = v.split('?')
      //   if (splited.length > 0) {
      //     this.thisLink = splited[0].toString()
      //   }
      // },
      //
      // setLink2 (v) {
      //   const splited = v.split('?')
      //   if (splited.length > 0) {
      //     this.thisLink_2 = splited[0].toString()
      //   }
      // },
      //
      // setTitle (v) {
      //   if (typeof v === 'string') {
      //     this.thisTitle = v.replace(/"/g, '\'')
      //   }
      // },
      //
      // setTitle2 (v) {
      //   if (typeof v === 'string') {
      //     this.thisTitle_2 = v.replace(/"/g, '\'')
      //   }
      // }

    },
    watch: {
      dialog (value) {
        // console.log('dialog value', value)
        if (!value) {
          this.$emit('input', false)
        }
      },

      switch1 (value) {
        if (value) {
          this.typeOfCalendar = 'month'
          this.selectedFastDateRange = 2
          this.onClickFastDateInterval('thisMonth')
        } else {
          this.typeOfCalendar = 'date'
          this.selectedFastDateRange = 1
          this.onClickFastDateInterval('yesterday')
        }

      }

      // value (value) {
      //   this.dialog = value
      // },
      //
      // // rawLink (v) {
      // //   const match = /(^[^?].*)(\?.*)$/.exec(v)
      // //   if (match) {
      // //     console.log('rawLink', match, match[1].toString(), this.thisLink)
      // //     this.thisLink = match[1].toString()
      // //   } else {
      // //     this.thisLink = v
      // //   }
      // // }
      //
      // // dep (v) {
      // //   if (v) {
      // //     this.$set(this, 'team', v.value)
      // //   } else {
      // //     this.$set(this, 'team', [])
      // //   }
      // // },
      //
      // thisTeam () {
      //   if (!this.init) {
      //     this.thisOwner = ''
      //     this.thisOwner_2 = ''
      //
      //   } else {
      //     this.init = false
      //   }
      //   // if (v) {
      //   //   if (this.teams.value.length === 0) {
      //   //     // this.$set(this, 'thisTeam_2', {value: []})
      //   //   } else if (this.teams.value.length === 1) {
      //   //     // this.$set(this, 'thisTeam_2', this.thisTeam)
      //   //   }
      //   // } else {
      //   //   // this.$set(this, 'thisTeam_2', {value: []})
      //   // }
      // },
      //
      // // team2 () {
      // //
      // //
      // // },
      // //
      // // thisTeam_2 (v) {
      // //   if (v) {
      // //     this.owners_2 = v.thisTeam
      // //   } else {
      // //     this.$set(this, 'owners_2', [])
      // //   }
      // // }
    }
  }
</script>

<style scoped>

</style>