<template>
  <v-container fluid>
    <EarningsNetworkWindowAddStatistic v-if="uploadDialog"
                                       :value="true"
                                       :pps="pps"
                                       @input="onUploadNewStatistic = uploadDialog = false"
    ></EarningsNetworkWindowAddStatistic>
    <v-row
        justify="start"
        align="start"
        width="100%"
        class="ml-3 pr-4"
    >
      <v-row>
        <v-col cols="12" sm="1" md="2">
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="430px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :background-color="switch1 ? 'blue lighten-2' : ''"
                  label="Date or Date range"
                  v-model="dateRangeText"
                  prepend-icon="mdi-calendar"
                  @click:prepend="menu = true"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>

            </template>
            <v-card flat>
              <v-row no-gutters>
                <v-col cols="4">

                  <v-list dense>
                    <!--                    <v-subheader class="justify-center"><v-icon >mdi-clock-fast</v-icon></v-subheader>-->
                    <v-subheader class="justify-center">
                      <v-icon>mdi-calendar-range-outline</v-icon>
                    </v-subheader>
                    <v-list-item-group
                        v-model="selectedFastDateRange"
                        color="primary"
                    >
                      <v-list-item
                          v-for="(item, i) in selectedFastDateRangeItems"
                          :key="i"
                      >
                        <!--                        <v-list-item-icon>-->
                        <!--                          <v-icon v-text="item.icon"></v-icon>-->
                        <!--                        </v-list-item-icon>-->
                        <v-list-item-content @click="onClickFastDateInterval(item.value)" v-if="item.month === switch1">
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>

                  </v-list>
                  <v-switch v-model="switch1" label="Month" class="pl-6" color="red"></v-switch>
                </v-col>
                <v-col cols="8">
                  <v-date-picker
                      v-model="dates"
                      :show-current="todayDate()"
                      reactive
                      first-day-of-week="1"
                      range
                      :type="typeOfCalendar"
                  ></v-date-picker>
                  <!--                  <v-spacer></v-spacer>-->
                  <div class="text-right">
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(dates);onclickOkPeekDate()"
                    >
                      OK
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>

          </v-menu>
          <!--          <v-row>-->
          <!--            <v-col-->
          <!--                cols="12"-->
          <!--                sm="6"-->
          <!--            >-->
          <!--              <v-date-picker-->
          <!--                  v-model="dates"-->
          <!--                  range-->
          <!--              ></v-date-picker>-->
          <!--            </v-col>-->
          <!--            <v-col-->
          <!--                cols="12"-->
          <!--                sm="6"-->
          <!--            >-->
          <!--              <v-text-field-->
          <!--                  v-model="dateRangeText"-->
          <!--                  label="Date range"-->
          <!--                  prepend-icon="mdi-calendar"-->
          <!--                  readonly-->
          <!--              ></v-text-field>-->
          <!--              model: {{ dates }}-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <!--          -->
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-3 mr-0"
              v-model="groupBy"
              label="Group By"
              :items="groupByItems"
              hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-3 mr-0"
              v-model="owner"
              label="Owner"
              :items="creators"
              hide-details
              :disabled="groupBy === 'Error'"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1">
          <v-select
              class="pt-3 mr-0"
              v-model="cab"
              :items="cabs"
              return-object
              label="Cab"
              hide-details
              clearable
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-3 mr-0"
              v-model="model"
              :items="models"
              return-object
              label="Model"
              hide-details
              clearable
              :disabled="groupBy === 'Error'"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              v-model="region"
              :items="allRegions"
              label="Region"
              clearable
              :disabled="groupBy === 'Error'"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-autocomplete
              class="pt-4"
              dense
              v-model="offer"
              :items="allOffers"
              label="Offer"
              clearable
              :disabled="groupBy === 'Error'"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1" md="1">
          <v-text-field
              v-model="searchName"
              hide-details
              label="Name"
              type="string"
              clearable
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="2" md="2">
          <v-pagination v-model="page" :length="pageCount" class="pt-3"></v-pagination>
        </v-col>

        <v-col cols="12" sm="1" md="1">
          <v-select
              class="pt-3 mr-0"
              v-model="itemsPerPage"
              :items="itemsPerPageOptions"
              hide-details
              solo
          ></v-select>
        </v-col>
      </v-row>
      <v-col cols="12" sm="12" md="12" class="pt-0 px-0">
        <v-data-table
            :headers="headers"
            :items="statistic"
            :page.sync="page"
            :server-items-length="total"
            :options.sync="options"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            multi-sort
            @page-count="pageCount = $event"
            @update:sort-desc="onUpdateSortTable"
            hide-default-footer
            dense
        >
          <template v-if="itemsPerPage > 10" v-slot:footer>
            <v-row
                justify="start"
                align="start"
                width="100%"
                class="pl-3 pr-4 pt-3"
            >
              <v-row>
                <v-col cols="12" sm="5" offset-md="7" md="5">
                  <v-pagination v-model="page" :length="pageCount"></v-pagination>
                </v-col>
              </v-row>
            </v-row>
          </template>

          <template slot="body.prepend">
            <tr class="primary--text">
              <th v-if="groupBy === 'Cab'"></th>
              <!--              <th v-if="groupBy === 'Campaign'"></th>-->
              <!--              <th v-if="groupBy === 'Campaign'"></th>-->
              <!--              <th v-if="groupBy === 'Campaign'"></th>-->
              <th></th>
              <th>{{totalEarning}}</th>
              <!--              <th>{{totalSale}}</th>-->
              <!--              <th>{{totalHold}}</th>-->
              <!--              <th>{{totalReject}}</th>-->
              <!--              <th>{{totalImps}}</th>-->
              <!--              <th>{{totalClicks}}</th>-->
            </tr>
          </template>
          <!--      <template v-slot:item.id="{ item }">-->
          <!--        <a target="_blank" rel="noopener noreferrer"-->
          <!--           :href="`https://admin.mgid.com/cab/goodhits/clients?client_currency=&agency=&client_id=${item.id}&login=&email=&domain=&curator=&partner=&inviter=&accompany_manager=&wagesAll=&deposit=&subnet=&color=&periodSelectInterval=&btnsubmit=Filter`"-->
          <!--        >{{ item.id}}-->
          <!--        </a>-->
          <!--      </template>-->

          <!--          <template v-slot:item.offer="{ item }">-->
          <!--            <v-tooltip bottom v-if="item.offer === 'Error'">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on" class="grey lighten-2">{{item.flowName}}</span>-->
          <!--              </template>-->
          <!--              <span>Error format name in Flow Name<br>-->
          <!--                Correct example : "UserName - OfferName_GEO ..."-->
          <!--              </span>-->

          <!--            </v-tooltip>-->
          <!--            &lt;!&ndash;            <span v-if="item.offer">{{item.offer}}</span>&ndash;&gt;-->
          <!--            &lt;!&ndash;            <span v-else>{{item.name}}</span>&ndash;&gt;-->
          <!--&lt;!&ndash;            <span v-else-if="item.cabName && item.cabName === 'DSP'">{{item.name}}</span>&ndash;&gt;-->
          <!--            <v-tooltip bottom v-else>-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <span v-on="on">{{item.offer}}</span>-->
          <!--              </template>-->
          <!--              <span>{{item.flowName}}</span>-->
          <!--            </v-tooltip>-->
          <!--          </template>-->

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import api from '@/utils/api'
  import { DateTime } from 'luxon'
  import {
    IFACE_EARNING_ADD_STATISTIC_WINDOW_OFF, IFACE_EARNING_ADD_STATISTIC_WINDOW_ON,
    // IFACE_RUNNING_BACKDATING_UPDATE_SPENT_OFF,
    // IFACE_SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS,
    // IFACE_SPENDING_CAMPAIGNS_LAST_UPDATE,
    IFACE_SPENDING_CAMPAIGNS_REFRESH_OFF,
    IFACE_SPENDING_CAMPAIGNS_REFRESH_ON,
    // IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_OFF,
    // IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_ON
  } from '@/store/actions/iface'

  import EarningsNetworkWindowAddStatistic from './EarningsNetworkWindowAddStatistic'

  export default {
    name: 'EarningsNetworks',
    components: {EarningsNetworkWindowAddStatistic},
    data () {
      return {
        tz: 'America/Los_Angeles',
        uploadDialog: false,
        menu: false,
        searchName: null,

        offer: null,
        allOffers: [],

        // campaignId: null,

        region: null,
        allRegions: [],

        dates: [],

        typeOfCalendar: 'date',

        options: {itemsPerPage: 20},

        switch1: false,

        itemsPerPageOptions: [5, 10, 15, 20, 30, 50, 100],
        headers: [],

        sortBy: ['earning'],
        sortDesc: [true],

        loading: false,

        model: null,
        models: ['CPC', 'DSP', 'Error'],

        /*
        date` date NOT NULL,
 `month` tinyint(1) NOT NULL DEFAULT 0,
 `model` varchar(255) NOT NULL,
 `owner` varchar(20) NOT NULL,
 `networkName` varchar(11) NOT NULL,
 `networkCabName` varchar(255) NOT NULL,
 `currency` varchar(255) NOT NULL,
 `region` varchar(255) NOT NULL,
 `offer` varchar(255) NOT NULL,
 `earning` float NOT NULL,
 `flowName` varchar(1024) NOT NULL,
         */

        // headersForGroupByCampaign: [
        //   {text: 'Date', value: 'date'},
        //   {text: 'Model', value: 'model',},
        //   {text: 'owner', value: 'owner'},
        //   {text: 'Net', value: 'networkName'},
        //   {text: 'Cab', value: 'networkCabName'},
        //   {text: 'Currency', value: 'currency'},
        //   {text: 'Region', value: 'region'},
        //   {text: 'Offer', value: 'offer'},
        //   {text: 'Earning', value: 'earning'},
        //   {text: 'sale', value: 'buying'},
        //   {text: 'flowName', value: 'flowName'}
        // ],

        headersForGroupByModel: [
          {text: 'Model', value: 'model'},
          {text: 'Earning', value: 'earning'},
        ],

        headersForGroupByDate: [
          {text: 'Date', value: 'date'},
          {text: 'Earning', value: 'earning'},
        ],

        headersForGroupByOwner: [
          {text: 'Owner', value: 'owner'},
          {text: 'Earning', value: 'earning'},
        ],

        headersForGroupByCab: [
          {text: 'Network', value: 'networkName'},
          {text: 'Cab', value: 'networkCabName'},
          {text: 'Earning', value: 'earning'}
        ],

        headersForGroupByRegion: [
          {text: 'Region', value: 'region'},
          {text: 'Earning', value: 'earning'}
        ],

        headersForGroupByOffer: [
          {text: 'Offer', value: 'offer'},
          {text: 'earning', value: 'earning'},
        ],

        headersForGroupByError: [
          {text: 'Flow name', value: 'offer'},
          {text: 'earning', value: 'earning'},
        ],

        statistic: [],
        total: 0,
        page: 1,
        pageCount: 0,
        itemsPerPage: 20,

        totalEarning: 0,
        // totalSale: 0,
        // totalHold: 0,
        // totalReject: 0,
        // totalImps: 0,
        // totalClicks: 0,

        selectedFastDateRange: 0,
        selectedFastDateRangeItems: [
          {text: 'Today', value: 'today', month: false},
          {text: 'Yesterday', value: 'yesterday', month: false},
          {text: 'This month', value: 'thisMonth', month: true},
          {text: 'Last month', value: 'lastMonth', month: true},
        ],

        groupBy: 'Offer',
        groupByItems: ['Error', 'Model', 'Offer', 'Region', 'Date', 'Owner', 'Cab'],

        owner: 'My',
        // owner: 'All',
        creators: [],

        cab: null,

        cabs: [],
        pps: []
      }
    },

    async created () {
      const date = this.todayDate()
      this.dates = [date, date]
      //
      // const c = await api.getCatalog()
      // //
      // // Vue.set(this, 'creators', ['All', 'My'].concat(c.data.allOwnersInfo))
      //
      // if (this.$store.getters['user/getUserName'] === 'denis') {
      //   this.owner = 'All'
      // }

      let catalog
      try {
        catalog = await api.getCatalogForEarnings()
      } catch (e) {
        console.error('created', e)
        return
      }

      this.$set(this, 'pps', catalog.data.pps)

      // console.log('created catalog', catalog)
    },

    async mounted () {
      this.getDataFromApi()
    },

    computed: {
      dateRangeText () {
        if (this.isToday()) return 'Today'
        if (this.isYesterday()) return 'Yesterday'
        if (this.isThisMonth()) return 'This month'
        if (this.isLastMonth()) return 'Last month'
        return this.dates.join(' ~ ')
      },

      refreshTable: {
        get () {
          return this.$store.getters['iface/getSpendingCampaignsRefreshStatus']
        },
        set (value) {
          if (value)
            this.$store.commit(IFACE_SPENDING_CAMPAIGNS_REFRESH_ON)
          else
            this.$store.commit(IFACE_SPENDING_CAMPAIGNS_REFRESH_OFF)
        }
      },

      // loading: {
      //   get () {
      //     return this.$store.getters['iface/getSpendingCampaignsRefreshIsLoading']
      //   },
      //   set (value) {
      //     if (value)
      //       this.$store.commit(IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_ON)
      //     else
      //       this.$store.commit(IFACE_SPENDING_CAMPAIGNS_TABLE_LOADING_OFF)
      //   }
      // },

      isRunningBackdatingUpdateSpent: {
        get () {
          return this.$store.getters['iface/getIsRunningBackdatingUpdateSpent']
        }
      },

      onUploadNewStatistic: {
        get () {
          return this.$store.getters['iface/isVisibleEarningAddStatisticWindowShow']
        },
        set (value) {
          if (value)
            this.$store.commit(IFACE_EARNING_ADD_STATISTIC_WINDOW_ON)
          else
            this.$store.commit(IFACE_EARNING_ADD_STATISTIC_WINDOW_OFF)
        }
      },
    },

    methods: {

      todayDate () {
        // const c = DateTime.local().setZone('America/Los_Angeles').toISODate()
        // console.log('cccccccccccc', c, )
        return DateTime.local().setZone(this.tz).toISODate()
      },

      yesterdayDate () {
        return DateTime.local().setZone(this.tz).endOf('day').plus({days: -1}).toISODate()
      },

      thisMonth () {
        if (this.switch1) {
          return DateTime.local().setZone(this.tz).startOf('month').toFormat('yyyy-MM')
        } else {
          return DateTime.local().setZone(this.tz).startOf('month').toISODate()
        }
      },

      lastMonth () {
        if (this.switch1) {
          return DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toFormat('yyyy-MM')
        } else {
          return DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate()
        }

      },

      isToday () {
        if (this.dates.length === 1)
          return this.todayDate() === this.dates[0]
        if (this.dates.length === 2)
          return this.todayDate() === this.dates[0] && this.todayDate() === this.dates[1]

        return false
      },

      isYesterday () {
        if (this.dates.length === 1)
          return this.yesterdayDate() === this.dates[0]
        if (this.dates.length === 2)
          return this.yesterdayDate() === this.dates[0] && this.yesterdayDate() === this.dates[1]

        return false
      },

      isThisMonth () {
        if (this.dates.length === 1)
          return this.thisMonth() === this.dates[0]
        return false
      },

      isLastMonth () {
        // const startMonth = DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString()
        // const endMonth = DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()

        if (this.dates.length === 1)
          return this.lastMonth() === this.dates[0]
        return false
      },

      onClickFastDateInterval (value) {
        switch (value) {
          case 'today' :
            this.dates = [this.todayDate()]
            break
          case 'yesterday' :
            this.dates = [this.yesterdayDate()]
            break
          case 'thisMonth' :
            this.dates = [this.thisMonth()]
            break
          case 'lastMonth' :
            this.dates = [
              this.lastMonth()]
            // DateTime.local().setZone(this.tz).plus({month: -1}).startOf('month').toISODate().toString(),
            // DateTime.local().setZone(this.tz).plus({month: -1}).endOf('month').toISODate().toString()]
            break
          default:
            throw `Unknown ${value}`
        }
      },

      onclickOkPeekDate () {
        this.getDataFromApi()
      },

      onUpdateSortTable () {
        this.getDataFromApi()
      },

      async getDataFromApi () {
        this.loading = true

        let startDate
        let endDate

        let owner
        // let clientId
        let offer
        let region
        // let campaignId

        let month

        if (this.switch1) {
          month = true
          if (this.dates.length === 1) {
            startDate = this.dates[0] + '-01'
            endDate = DateTime.fromISO(this.dates[0]).endOf('month').toISODate()
          } else if (this.dates.length === 2) {
            startDate = this.dates[0] + '-01'
            endDate = DateTime.fromISO(this.dates[1]).endOf('month').toISODate()
          } else
            throw 'Error dates'
        } else {
          if (this.dates.length === 1) {
            startDate = this.dates[0]
            endDate = this.dates[0]
          } else if (this.dates.length === 2) {
            startDate = this.dates[0]
            endDate = this.dates[1]
          } else
            throw 'Error dates'
        }

        let networkCabName
        if (this.cab) {
          networkCabName = this.cab
        }

        // if (this.switch1) {
        //   try {
        //     await api.runFetchCampaignsDayStatistic(startDate, endDate)
        //   } catch (e) {
        //     console.error('getDataFromApi', e)
        //   }
        //   this.switch1 = false
        //   this.loading = false
        //   return
        // }

        if (this.owner === 'All') {
          owner = undefined
        } else if (this.owner === 'My') {
          owner = this.$store.getters['user/getUserName']
        } else {
          owner = this.owner
        }

        // if (this.cab.name !== 'All') {
        //   clientId = this.cab.id
        // }

        if (this.region)
          region = this.region

        if (this.offer)
          offer = this.offer

        // if (this.campaignId)
        //   campaignId = this.campaignId

        let flowName

        if (this.searchName !== null) {
          flowName = this.searchName
        }

        switch (this.groupBy) {
          // case 'Campaign':
          //   Vue.set(this, 'headers', this.headersForGroupByCampaign)
          //   break
          case 'Date':
            Vue.set(this, 'headers', this.headersForGroupByDate)
            break
          case 'Model':
            Vue.set(this, 'headers', this.headersForGroupByModel)
            break
          case 'Owner':
            Vue.set(this, 'headers', this.headersForGroupByOwner)
            break
          case 'Cab':
            Vue.set(this, 'headers', this.headersForGroupByCab)
            break
          case 'Region':
            Vue.set(this, 'headers', this.headersForGroupByRegion)
            break
          case 'Offer':
            Vue.set(this, 'headers', this.headersForGroupByOffer)
            break
          case 'Error':
            Vue.set(this, 'headers', this.headersForGroupByError)
            break
        }

        // this.sortDesc, this.sortBy
        // const sortBy = this.sortBy.length > 0
        //   ?
        //   this.sortBy.reduce((acc, n, index) => {
        //     acc[n] = this.sortDesc[index]
        //     return acc
        //   }, {})
        //   : undefined
        //
        // console.log('sortBy', sortBy)

        const sortBy = this.sortBy.length > 0 ? this.sortBy : undefined
        const sortDesc = this.sortDesc.length > 0 ? this.sortDesc : undefined

        const request = {
          limit: this.itemsPerPage,
          page: this.page - 1,
          startDate,
          endDate,
          groupBy: this.groupBy,
          owner,
          flowName,
          sortBy,
          sortDesc,
          offer,
          region,
          month,
          networkCabName
        }

        try {
          const res = await api.getEarningStatistic(request)
          // console.log('res', res)

          if (res.data.error) {
            console.info('getEarningStatistic Res', res.data)
            return
          }

          // this.$store.commit(IFACE_SPENDING_CAMPAIGNS_LAST_UPDATE, Math.round((Date.now() / 1000 - res.data.lastUpdate) / 60))
          this.$set(this, 'allOffers', res.data.offers)
          this.$set(this, 'allRegions', res.data.regions)
          this.$set(this, 'creators', ['All', 'My'].concat(res.data.creators))
          this.$set(this, 'total', res.data.count)
          this.$set(this, 'statistic', res.data.statistic)
          this.$set(this, 'cabs', res.data.networkCabNames)
          this.$set(this, 'totalEarning', res.data.earning)
          // this.$set(this, 'totalSpent', res.data.spent)
          // this.$set(this, 'totalSale', res.data.buying)
          // this.$set(this, 'totalReject', res.data.interest)
          // this.$set(this, 'totalHold', res.data.decision)
          // this.$set(this, 'totalImps', res.data.imps)
          // this.$set(this, 'totalClicks', res.data.clicks)

          // if ('fetchStatus' in res.data) {
          //   this.$store.commit(IFACE_SET_RUNNING_BACKDATING_UPDATE_SPENT_STATUS, res.data.fetchStatus)
          // } else {
          //   if (this.isRunningBackdatingUpdateSpent) {
          //     this.$store.commit(IFACE_RUNNING_BACKDATING_UPDATE_SPENT_OFF)
          //   }
          // }

        } catch (e) {
          console.error('getDataFromApi', e)
        }
        this.loading = false
      },
    },

    watch: {
      page () {
        this.getDataFromApi()
      },

      itemsPerPage (value) {
        this.$set(this.options, 'itemsPerPage', parseInt(value, 10))
        this.getDataFromApi()
      },

      owner () {
        this.getDataFromApi()
      },

      groupBy (value) {
        this.getDataFromApi()
        if (value === 'Error') {
          this.owner = 'All'
        }
      },

      cab () {
        this.getDataFromApi()
      },

      offer () {
        this.getDataFromApi()
      },

      region () {
        this.getDataFromApi()
      },

      searchName () {
        this.getDataFromApi()
      },

      campaignId () {
        this.getDataFromApi()
      },

      model () {
        this.getDataFromApi()
      },

      refreshTable (value) {
        if (value) {
          this.getDataFromApi()
        }
      },

      onUploadNewStatistic (value) {
        if (value) {
          this.uploadDialog = true
        }
        // else {
        //   this.getDataFromApi()
        // }
      },

      switch1 (value) {
        if (value) {
          this.selectedFastDateRange = 2
          this.typeOfCalendar = 'month'
          this.onClickFastDateInterval('thisMonth')
        } else {
          this.selectedFastDateRange = 0
          this.typeOfCalendar = 'date'
          this.onClickFastDateInterval('yesterday')
        }
      }
    }
  }
</script>

<style scoped>

</style>